import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const IndustryDetailsSection = styled.section`
  background-color: ${rspTheme.palette.primary.background};
  padding: 84px 0 1px;

  h2 {
    color: ${rspTheme.palette.primary.main};
    font-size: ${rspTheme.typography.h2.fontSize};
    font-weight: ${rspTheme.typography.h2.fontWeight};
    line-height: ${rspTheme.typography.h2.lineHeight};
    letter-spacing: ${rspTheme.typography.h2.letterSpacing};
    margin: 40px 0 16px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin: 24px 0 36px;
    }
  }

  h3 {
    color: ${rspTheme.palette.primary.main};
    font-size: ${rspTheme.typography.h3.fontSize};
    font-weight: ${rspTheme.typography.h3.fontWeight};
    line-height: ${rspTheme.typography.h3.lineHeight};
    letter-spacing: ${rspTheme.typography.h3.letterSpacing};
    margin: 40px 0 16px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin: 24px 0 36px;
    }
  }

  p {
    color: ${rspTheme.palette.text.primary};
    font-size: ${rspTheme.typography.body1.fontSize};
    font-weight: ${rspTheme.typography.body1.fontWeight};
    line-height: ${rspTheme.typography.body1.lineHeight};
    letter-spacing: ${rspTheme.typography.body1.letterSpacing};
    margin-bottom: 16px;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      line-height: 1.9;
    }
  }

  &.themeWhite {
    background-color: transparent;
  }

  &.themeDark {
    background-color: ${rspTheme.palette.primary.dark};
    color: #ffffff;

    h2,
    h3 {
      color: inherit;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    h2,
    h3 {
      font-size: 2.8rem;
    }

    p {
      columns: unset;
    }
  }
`
